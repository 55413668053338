<template>
  <div class="form-group">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>
