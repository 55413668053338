<template>
  <ion-page>
    <ion-content :fullscreen="true" class="bg-lighter ion-content">
      <vertical-nav-menu></vertical-nav-menu>
      <div class="nk-wrap">
        <nav-bar></nav-bar>
        <main-block>
          <block-head title="API Keys" description="Third party APIs credentials"></block-head>
          <block-content>
            <div class="d-flex py-5 justify-center bg-white" v-if="dataIsLoading">
              <ion-spinner></ion-spinner>
            </div>
            <card v-else no-padding class="p-3">
              <template v-slot:grouped>
                <div class="card-inner" v-for="api in apiKeys" :key="'api'+api.id">
                  <div class="between-center flex-wrap flex-md-nowrap g-3">
                    <div class="nk-block-text">
                      <h6>{{ api.nice_name }}</h6>
                      <p>{{ api.description }}</p>
                      <template v-if="api.api_key.length || api.api_secret.length">
                        <div v-if="api.api_key.length" class="text-muted">
                          <nio-icon icon="ni-lock-fill"></nio-icon>
                          <span class="nk-text-muted mr-2"> API KEY: </span> {{ api.api_key }}
                        </div>
                        <div v-if="api.api_secret.length" class="text-muted">
                          <nio-icon icon="ni-lock-fill"></nio-icon>
                          <span class="nk-text-muted mr-2"> API SECRET: </span> {{ api.api_secret }}
                        </div>
                      </template>
                      <div v-else class="text-muted">No credentials.</div>
                    </div>
                    <div class="nk-block-actions">
                      <nk-button type="primary"
                                 v-if="!api.api_key.length && !api.api_secret.length"
                                 v-on:click="editAPI(api)">Set credentials</nk-button>
                      <nk-button type="dark" v-else v-on:click="editAPI(api)">Update credentials</nk-button>
                    </div>
                  </div>
                </div>
              </template>
            </card>
          </block-content>
        </main-block>
      </div>
    </ion-content>
    <ion-modal
        :is-open="isModalOpenRef"
        @didDismiss="setModalOpenStatus(false)"
    >
      <i-modal title="API Credentials" @modal-close="setModalOpenStatus(false)">
        <form-group>
          <label class="overline-title" for="api_name">API Name</label>
          <b-input id="api_name" v-model="editedAPI.nice_name"/>
        </form-group>
        <form-group>
          <label class="overline-title" for="api_key">API Key</label>
          <b-input id="api_key" v-model="editedAPI.api_key"/>
        </form-group>
        <form-group v-if="editedAPI.has_api_secret">
          <label class="overline-title" for="api_secret">API Secret</label>
          <b-input id="api_secret" v-model="editedAPI.api_secret"/>
        </form-group>
        <form-group>
          <label class="overline-title" for="api_description">Description</label>
          <textarea class="form-control" id="api_description" v-model="editedAPI.description"></textarea>
        </form-group>
        <template v-slot:footer class="p-5">
          <nk-button type="primary" class="mr-2" v-on:click="changeAPI">Update Credentials</nk-button>
          <a href="javascript:;" class="link link-light" v-on:click="setModalOpenStatus(false)">Cancel</a>
        </template>
      </i-modal>
    </ion-modal>
  </ion-page>
</template>

<script>
import {IonContent, IonPage, loadingController, toastController, IonModal, IonSpinner} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref} from 'vue';
import VerticalNavMenu from "@/@core/layouts/vertical-nav-menu/VerticalNavMenu";
import NavBar from "@core/layouts/app-nav-bar/NavBar";
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import axios from "@/libs/axios"
import {Card} from "@core/components/cards";
import IModal from "@core/components/ion-modal/IModal";


import "bootstrap"


import BlockHead from "@core/layouts/main-block/components/BlockHead";
import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";
import FormGroup from "@core/layouts/form-group/FormGroup";
import BInput from "@core/components/bp-form/components/BInput";

export default defineComponent({
  components: {
    BInput,
    FormGroup,
    NkButton,
    NioIcon,
    BlockHead,
    Card,
    BlockContent,
    MainBlock,
    NavBar,
    VerticalNavMenu,
    IonContent,
    IonPage,
    IonModal,
    IonSpinner,
    IModal,
  },
  setup() {

    let dataIsLoading = ref(false)
    let apiKeys   = ref([])
    let editedAPI = reactive({
      api_key: '',
      api_secret: '',
      description: '',
      has_api_secret: false,
      id: 0,
      nice_name: '',
    })
    let isModalOpenRef = ref(false)

    const getAPIKeys = async () => {
      dataIsLoading.value = true
      axios.post('/api/admin/settings/api_keys/list')
          .then( (resp) => {
            for(let x in resp.data.data){
              apiKeys.value.push( resp.data.data[x])
            }
          })
          .catch( err => {
            console.log('APIKeys.vue ajax error: ', err)
          })
          .then(()=> dataIsLoading.value = false)
    }

    onMounted(()=> getAPIKeys())

    const editAPI = ( api ) => {

      editedAPI.api_key = editedAPI.api_secret = 'unchanged'
      editedAPI.id             = api.id
      editedAPI.description    = api.description
      editedAPI.has_api_secret = api.has_api_secret
      editedAPI.nice_name      = api.nice_name
      setModalOpenStatus(true)
    }
    const setModalOpenStatus = (st) => isModalOpenRef.value = st
    const changeAPI = async () => {

      const loading = await loadingController.create({message: 'Processing...'})
      const toast   = await toastController.create({position: "top", duration: 3000})
      await loading.present()


      axios.post('/api/admin/settings/api_keys/update', editedAPI)
          .then( resp => {

            toast.message = resp.data.success ? 'Thank you! API updated.' : resp.data.message
            toast.color   = resp.data.success ? "primary" : "danger"
            if( resp.data.success ){

              setModalOpenStatus(false)

              let data = resp.data.data[0]
              for( let x in apiKeys.value ){
                if( apiKeys.value[x].id === editedAPI.id ){
                  apiKeys.value[x].api_key     = data.api_key
                  apiKeys.value[x].api_secret  = data.api_secret
                  apiKeys.value[x].description = editedAPI.description
                  apiKeys.value[x].nice_name   = editedAPI.nice_name
                  break
                }
              }
            }
            toast.present()
          })
          .catch(error => {
            toast.message = 'Error: '+error.response.status+' '+error.response.statusText
            toast.color = "danger"
            toast.present()
          })
          .then( () => loading.dismiss())
    }

    return {
      apiKeys,
      changeAPI,
      dataIsLoading,
      editAPI,
      editedAPI,
      isModalOpenRef,
      setModalOpenStatus,
    }
  }
});

</script>
