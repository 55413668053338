<template>
  <div class="card-title-group">
    <div class="card-title" v-if="title">
      <h6 class="title">{{ title }}</h6>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
