<template>
  <input class="form-control" :class="classesList" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"/>
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
  props: {
    size: undefined,
    modelValue: null,
  },
  setup( props ){
    let classesList = []
    if(props.size !== undefined){
      classesList.push(`form-control-${props.size}`)
    }
    return {
      classesList,
    }
  }
})
</script>
