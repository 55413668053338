<template>
  <div class="card-search search-wrap" :class="{active: isActive}">
    <div class="card-body">
      <div class="search-content">
        <a href="javascript:;" class="search-back btn btn-icon toggle-search" v-on:click="closeSearch">
          <nio-icon icon="ni-arrow-left"></nio-icon>
        </a>
        <input :value="searchTerm"
               v-on:input="searchIt($event.target.value)"
               type="text"
               class="form-control border-transparent form-focus-none"
               :placeholder="placeholder ? placeholder : 'Search here'">
        <nk-button type="" is-icon-only-button="" class="search-submit">
          <nio-icon icon="ni-search"></nio-icon>
        </nk-button>
      </div>
    </div>
  </div>
</template>

<script>
import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";

export default {
  name: "CardSearch",
  components: {NkButton, NioIcon},
  props: {
    isActive: Boolean,
    placeholder: String,
    searchTerm: String,
  },
  emits: ['doSearch', 'closeSearch'],
  setup(props, {emit}){

    const searchIt = (v) => {
      emit('doSearch', v)
    }

    const closeSearch = () => {
      emit('closeSearch')
    }

    return {
      searchIt,
      closeSearch,
    }

  },
}
</script>
